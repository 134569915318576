<template>
  <div class="d-flex flex-row text-left">
    <div ref="viewer" class="flex-grow-1 position-relative">
      <!-- Buttons Overlay -->
      <div
        id="viewer-overlay"
        class="w-100 vh-100 d-flex align-items-center justify-content-between px-3"
        style="pointer-events: none"
        oncontextmenu="false"
      >
        <!-- Left button -->
        <span
          id="left-button"
          class="next-prev-button"
          @click="navButton(false)"
        >
          <img
            :src="require('@/assets/icons/arrow-left.svg')"
            class="w-100 h-100 user-select-none d-none"
            draggable="false"
          />
        </span>

        <!-- Loader spinner -->
        <b-spinner
          class="spinner user-select-none"
          v-if="isLoadingModel"
          large
        ></b-spinner>

        <!-- Right button -->
        <span
          id="right-button"
          class="next-prev-button"
          @click="navButton(true)"
        >
          <img
            :src="require('@/assets/icons/arrow-left.svg')"
            class="w-100 h-100 flip user-select-none d-none"
            draggable="false"
          />
        </span>
        <div @click="clear()" class="reset-button">
          <img
            src="@/assets/icons/reset.svg"
            draggable="false"
            class="w-100 h-100"
          />
        </div>
        <div id="viewer-watermark">
          <!-- powered by MetaChef -->
          <a href="https://www.metachef.nl">
            <img class="copyright" src="@/assets/DCPowered.png" />
          </a>
        </div>
      </div>
    </div>
    <Sidebar class="py-5">
      <!-- put in tabs here -->
      <Tabs :tabs="tabsList">
        <template #tab0>
          <PopdotPackageConfigTab ref="packageconf"></PopdotPackageConfigTab>
        </template>
        <template #tab1>
          <PopdotPropertiesTab></PopdotPropertiesTab>
        </template>
      </Tabs>
    </Sidebar>
  </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";

import ViewerCore from "@digital-chef/viewer-core";
class Viewer extends ViewerCore {
  constructor(canvas) {
    super(canvas);
    this.rendering.loadCubemap(
      "https://firebasestorage.googleapis.com/v0/b/digitalchef-viewer/o/cubemaps%2FEtnies_Park_Center_1k.jpg?alt=media&token=7dface5c-02bd-4df8-834e-17246587d168",
      "https://firebasestorage.googleapis.com/v0/b/digitalchef-viewer/o/cubemaps%2FEtnies_Park_Center_1k.jpg?alt=media&token=7dface5c-02bd-4df8-834e-17246587d168"
    );
  }
}

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronCircleUp);
import { mapGetters } from "vuex";

import $ from "jquery";

export default {
  components: {
    Sidebar: () => import("@/components/Sidebar"),
    Tabs: () => import("@/components/Tabs.vue"),
    PopdotPackageConfigTab: () =>
      import("@/components/Product/Editor/PopdotPackageConfigTab.vue"),
    PopdotPropertiesTab: () =>
      import("@/components/Product/Editor/PopdotPropertiesTab.vue"),
  },
  data() {
    return {
      dashboardData: undefined,
      materials: undefined,
      productData: Object,
      isLoadingModel: true,
    };
  },
  mounted() {
    document.title = `Popdot Configurator - Europastry`;
    /*this.setupScrollbar();
    //I don't like the way I did this but it worked like this, didn't test with more than 1 bar tho :^).
    document.getElementsByClassName("simplebar-vertical")[0].style.bottom =
      "2vh";
    */

    axios
      .post(`${config.apiUrl}database/getDashboardConfig`, {
        idToken: this.user.idToken,
        dashboardUID: this.appConfig.dashboardUID,
      })
      .then((result) => {
        this.dashboardData = result.data;
        this.load();
      });
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "user",
      "sidebarState",
      "productUIDs",
      "productCodes",
      "allProductCodes",
    ]),

    bodyStyle: function () {
      return {
        background: "red",
        // "margin-right": this.sidebarState ? "24em" : "0",
      };
    },
    /**
     * Adapt list width when the sidebar opens
     */
    listStyle: function () {
      return {
        "padding-right":
          this.$mq !== "sm" && this.sidebarState
            ? this.sidebarWidth + "px"
            : 10,
      };
    },
    backToTopStyle: function () {
      return {
        right: this.sidebarState
          ? `calc(${document.getElementById("sidebarWidth").offsetWidth + 39}px`
          : "1em",
      };
    },

    tabsList: function () {
      return [
        { label: "Package configurator", active: true },
        { label: "Popdot customizer", active: true },
      ];
    },
  },
  methods: {
    loadNewProduct: async function (product) {
      if (this.isLoadingModel) return;
      this.isLoadingModel = true;
      return this.viewer
        .loadProduct(
          config.apiUrl,
          product,
          this.user.idToken,
          this.appConfig.dashboardUID
        )
        .then(() => {
          this.isLoadingModel = false;
          return this.viewer.loadedProduct.config.exclusivePackage;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadNewCoating: function (coatingColour) {
      if (this.isLoadingModel) return;
      this.viewer.loadNewCoating(coatingColour);
    },
    loadNewPopdotAtPosition: async function (product, position) {
      return new Promise((resolve, reject) => {
        if (this.isLoadingModel)
          return reject("Viewer is already loading a model");

        this.isLoadingModel = true;

        const pos = position;
        this.viewer
          .loadNewPopdotAtPosition(
            config.apiUrl,
            product,
            this.user.idToken,
            this.appConfig.dashboardUID,
            pos
          )
          .then(() => {
            this.isLoadingModel = false;
            return resolve();
          })
          .catch((err) => {
            this.isLoadingModel = false;
            return reject(err);
          });
      });
    },
    removePopdotFromQueue: function (productCode) {
      this.viewer.removePopdotFromQueue(productCode);
    },
    setupScrollbar: function () {
      return;
      // eslint-disable-next-line no-unreachable
      const ref = this.$refs.body.SimpleBar;
      const productsScrollbar = ref.getScrollElement();
      productsScrollbar.addEventListener("scroll", this.onScroll);
    },
    onScroll: function (scrollbar) {
      if (scrollbar.target.scrollTop > 100) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
    },
    clear: function () {
      this.$refs.packageconf.reset();
      this.viewer.clear();
    },
    load: async function () {
      //console.log("amLoad");
      this.$store.commit("toggleSidebar", true);
      if (this.viewer === undefined) {
        this.isLoadingModel = true;
        const viewerRef = document.createElement("canvas");
        viewerRef.id = "application-canvas";
        viewerRef.className = "w-100 h-100";
        this.$refs.viewer.appendChild(viewerRef);
        this.viewer = new Viewer(viewerRef);

        this.viewer.app.on("update", this.resizeAppCanvas);

        this.viewer.setupCamera({
          layers: [0, 1, 3, 4 /*, 2*/],
        });
        this.viewer.app.start();
        //TODO: hardcoding sugar popdot for now
        this.isLoadingModel = false;
        this.$store.commit("setViewer", this.viewer);
        /*
        this.viewer
          .loadProduct(
            config.apiUrl,
            "CNTj3Z2RHS4KiVHUNaSj",
            this.user.idToken,
            this.appConfig.dashboardUID
          )
          .then(() => {
            this.isLoadingModel = false;
            this.$store.commit("setViewer", this.viewer);
          })
          .catch((err) => {
            // console.log(err);
            ///TODO: investigate this error. Might just be updating Ammo WASM
            /// https://github.com/kripken/ammo.js/pull/332
            /// https://github.com/kripken/ammo.js/pull/332/commits/4e73504265e98c35ee454b17fbb47c0c55b1ec8b

            if (err.includes) {
              if (err.includes("ALLOW_TABLE_GROWTH")) {
                console.warn(
                  "WASM table growth error hotfix - reloading page.\nError:",
                  err
                );
                return location.reload();
              }
            }
            console.error("failed loading", this.productCode, err);
            const pc = this.productCode;
            setTimeout(() => {
              alert(`Failed loading product '${pc}'`);
            }, 1000);
            this.$router.push("/");
          });
          */
      }
    },

    destroy: function () {
      this.$refs.viewer.removeChild(this.viewer.app.graphicsDevice.canvas);
      this.viewer.app.off("update");
      this.viewer.unload();
      this.viewer = undefined;
    },

    resizeAppCanvas: function () {
      if (this.viewer && this.$refs.viewer) {
        this.viewer.app.resizeCanvas(
          this.$refs.viewer.clientWidth,
          this.$refs.viewer.clientHeight
        );
      }
    },

    // Buttons
    backButton: function () {
      this.$store.commit("toggleSidebar", false);
      this.$router.push("/");
    },
    navButton: async function (dir) {
      let changeDir = this.viewer.navigateAnnotation(dir);
      if (this.isLoadingModel) return;

      return;
      // eslint-disable-next-line no-unreachable
      let nextProductCode;
      if (this.$store.getters.appConfig.mode === 0) {
        //If Big Dashboard
        const currentIndex = this.productUIDs.indexOf(this.productCode);

        if (changeDir === "up") {
          if (currentIndex + 1 >= this.productUIDs.length) {
            nextProductCode = this.productUIDs[0];
          } else {
            nextProductCode = this.productUIDs[currentIndex + 1];
          }
        } else if (changeDir === "down") {
          if (currentIndex - 1 < 0) {
            nextProductCode = this.productUIDs[this.productUIDs.length - 1];
          } else {
            nextProductCode = this.productUIDs[currentIndex - 1];
          }
        }
      } else {
        //Any other Dashboard
        if (changeDir === "") return;
        const currentIndex = this.allProductCodes.indexOf(this.productCode);
        if (changeDir === "up") {
          if (currentIndex + 1 >= this.allProductCodes.length) {
            nextProductCode = this.allProductCodes[0];
          } else {
            nextProductCode = this.allProductCodes[currentIndex + 1];
          }
        } else if (changeDir === "down") {
          if (currentIndex - 1 < 0) {
            nextProductCode = this.allProductCodes[
              this.allProductCodes.length - 1
            ];
          } else {
            nextProductCode = this.allProductCodes[currentIndex - 1];
          }
        }
      }

      console.log(nextProductCode);
      //Disabled going to another product.
      /*let editMode = "";
      if (this.$route.path.includes("/edit")) editMode = "/edit";
      this.$router.push(`/product/${nextProductCode}${editMode}`);*/
    },
    savePopdot: function () {
      console.log("saving popdot!");
      const data = [];
      for (let i = 0; i < this.materials.length; i++) {
        const mat = this.materials[i];
        data[i] = this.fixMaterialForDB(mat);
      }
      this.$emit("update-prop", `materials`, data);
    },
    updateProp: function (key, value) {
      this.productData[key] = value;
      axios.post(`${config.apiUrl}database/updateProductData`, {
        idToken: this.user.idToken,
        product: this.productCode,
        key: key,
        value: value,
        dashboardUID: this.appConfig.dashboardUID,
      });
      console.log("Editor update", key, value);
      this.$forceUpdate();
      this.$store.commit("tabUpdater");
    },
  },
};
</script>

<style lang="scss" scoped>
#viewer-overlay {
  position: absolute;
  transition: all 0.3s ease-in-out;
  bottom: 0;

  #left-button {
    left: 2vh;
  }
  #right-button {
    right: 2vh;
  }
  .b-overlay .bg-light {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
  }

  .nav-buttons {
    padding-left: 1em;
    padding-right: 1em;
  }

  .next-prev-button {
    width: 2.5em;
    cursor: pointer;
    pointer-events: all;
  }

  .flip {
    transform: scaleX(-1);
  }

  .back-button {
    width: 6em;
    cursor: pointer;
    position: absolute;
    bottom: 1em;
    right: 1em;
    pointer-events: all;
  }

  .spinner {
    width: 5rem;
    height: 5rem;
    border: 0.5em solid #f1f1f1;
    border-right-color: transparent;
  }

  #viewer-watermark {
    left: 0;
    bottom: 0;
    padding: 1rem 0;
  }

  .reset-button,
  #viewer-watermark {
    position: absolute;
    z-index: 10;
    pointer-events: all;
  }

  .reset-button {
    width: 6em;
    cursor: pointer;
    position: absolute;
    bottom: 1em;
    right: 1em;
    pointer-events: all;
  }

  #viewer-watermark > a > img {
    max-width: 160px;
  }
}
</style>
